.story {
    font-family: Calibri;
}
section {
    clear: both;
}

/* Introduction */
.story-hero {
    position: relative;
    padding: 0;
    overflow: hidden;
}
.story-bg-image {
    position: absolute;
    z-index: 0;
    bottom: 0;
    left: 0;
    padding: 0;
    margin: 0;
    object-fit: cover;
    object-position: 50% 50%;
    width: 100%;
    height: auto;
    min-height: 100%;
}
.story-introduction {
    min-height: 625px;
    text-align: center;
    align-items: center;
}
.story-introduction h1 {
    margin: 30px 0;
}
.story-introduction h2 {
    margin: 30px 0;
    padding-right: 150px;
    color: #707070;
    font-size: 40px;
    font-style: italic;
    font-family: Brush Script MT;
}
.story-introduction p {
    padding: 30px 50px;
    font-size: 22px;
    letter-spacing: 0.5px;
    line-height: 1.8;
}

/* Banner 1 */
.story-banner-1 {
    min-height: 270px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #FAF8F5;
}
.banner-1-row {
    align-items: center;
}
.banner-1-col-1 img {
    border-radius: 10px;
}
.banner-1-col-2 p {
    padding: 0 30px;
    font-size: 22px;
    letter-spacing: 0.5px;
    line-height: 1.8;
}

/* Main Story */
.story-main {
    display: flex;
    align-items: center;
    text-align: center;
    background-image: url('./logo-transparent-tree.png');
    background-size: 1500px 1500px;
    background-position: bottom -355px center;
    background-repeat: no-repeat;
}

.story-main .container-fluid {
    padding: 50px 50px;
}

.main-story-container {

}

.main-story {
    margin: 0 auto;

    columns: 30em;
}

.main-story h1 {
    text-align: center;
    margin-bottom: 1em;
    column-span: all;
    font-weight: 900;
}

.main-story p {
    margin-top: 0;
    margin-right: 20px;
    margin-left: 20px;
    letter-spacing: 0.5px;
    line-height: 1.8;
    font-size: 1.2rem;
}

/* Banner 2 */
.story-banner-2 {
    height: 270px;
    margin-bottom: 55px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FAF8F5;
    color: #606060;
}
.story-banner-2 h1 {
    font-style: italic;
}

@media (max-width: 575.98px) {
   .story-main .container-fluid {
        padding: 20px 5px;
    }

    .main-story h1 {
        margin-bottom: 0.8em;
    }

    .main-story p {
        margin-top: 0;
        letter-spacing: 0.4px;
        line-height: 1.3;
        font-size: 1rem;
    }
}

@media (max-width: 767.98px) {
    .story-introduction {
        min-height: 625px;
    }
    .story-introduction h1 {
        margin: 20px 0;
    }
    .story-introduction h2 {
        margin: 30px 0;
        padding-right: 0;
    }
    .story-introduction p {
        padding: 15px;
        font-size: 18px;
        letter-spacing: 0.5px;
        line-height: 1.5;
    }
    .banner-1-col-1 img {
        max-width: 120px;
        height: auto;
    }
    .banner-1-col-2 p {
        padding: 0;
        font-size: 16px;
        letter-spacing: 0.2px;
        line-height: 1.5;
    }
    .story-main {
        min-height: 700px;
    }
    .main-row p {
        font-size: 20px;
        letter-spacing: 0.3px;
        line-height: 1.6;
        padding: 5px;
        border-radius: 10px;
        background-color: rgba(255, 255, 255, 0.5);
    }
}

@media (min-width: 1200px) {
    .story-bg-image {
        position: absolute;
        z-index: 0;
        bottom: -200px;
        left: 0;
        padding: 0;
        margin: 0;
        object-fit: cover;
        object-position: 50% 50%;
        width: 100%;
        height: auto;
        min-height: 100%;
    }
    .story-main .container-fluid {
        padding: 100px 200px;
    }
}
