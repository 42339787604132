.about {
    font-family: Calibri;
}

.about-banner {
    text-align: center;
    padding: 15px;
    margin: 20px 0;
    color: #707070;
}
.about h4 {
    color: #7A8F3A;
    font-weight: bold;
}
.about span {
    font-size: 18px;
}
.about img {
    /*box-shadow: 0 10px 6px -6px #777;*/
    margin-bottom: 10px;
}

/* Team - first row */
.team-section-1 {
    margin-bottom: 30px;
}

/* Team - second row */
.team-section-2 {
    margin-bottom: 20px;
}

/* Statement */
.about-message {
    height: 280px;
    margin: 70px 0 55px;
    align-items: center;
    justify-content: center;
    background-color: #FAF8F5;
}

@media (max-width: 575.98px) {
    .team-section-1 {
        padding-left: 35px;
    }
    .team-section-2 {
        padding-left: 35px;
    }
    img#jenaV {
        width: auto;
        height: 180px;
    }
    img#chrisI {
        width: 150px;
        height: auto;
    }
    img#cathyI {
        width: auto;
        height: 140px;
    }
    img#loriA {
        width: 150px;
        height: auto;
    }
    img#paigeE {
        width: 150px;
        height: auto;
    }
    img#lauraR {
        width: 150px;
        height: auto;
    }
    img#alfredV {
        width: 150px;
        height: auto;
    }
}

@media (max-width: 400px) {
    .team-section-1 {
        padding-left: 0;
    }
    .team-section-2 {
        padding-left: 0;
    }
}

@media (min-width: 992px) {
    .team-row-2 {
        padding-left: 25px
    }
}

@media (max-width: 991.98px) {
    .last-member {
        padding-top: 30px;
    }
}
