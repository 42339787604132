.home {
    font-family: Calibri;
}

section {
    clear: both;
}

/* First section after header */
.home-hero {
    position: relative;
    padding: 0;
    overflow: hidden;
}
.home-bg-image {
    position: absolute;
    z-index: 0;
    bottom: 0;
    left: 0;
    padding: 0;
    margin: 0;
    object-fit: cover;
    object-position: 50% 50%;
    width: 100%;
    height: auto;
    min-height: 100%;
}
.home-introduction {
    z-index: 1;
    max-height: 679px;
    min-height: 679px;
}
.home-introduction p {
    opacity: 0.8;
}
.main-introduction {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-size: 30px;
    letter-spacing: 1px;
}
.main-introduction .btn-primary {
    background-color: #6AA8FC;
    border-color: #6AA8FC;
}
.main-introduction .btn-info {
    background-color: #3787FF;
    border-color: #3787FF;
}

/* Our Mission */
.home-mission-statement {
    background-color: #FAF8F5;
}
.mission-row {
    min-height: 350px;
    align-items: center;
    justify-content: center;
}
.mission-col {
    text-align: center;
    justify-content: center;
}
.mission-col p {
    max-width: 900px;
    margin: auto;
    margin-bottom: 25px;
    font-size: 22px;
    letter-spacing: 0.5px;
    line-height: 1.5;
}

/* Who We Serve */
.home-serve {
    background-image: url('./logo-transparent-tree.png');
    background-size: 1000px 1000px;
    background-position: bottom -240px right -450px;
    background-repeat: no-repeat;
    background-attachment: scroll;
}
.serve-row {
    min-height: 550px;
    max-height: 700px;
    align-items: center;
}
.serve-col {
    padding: 3em 10px;
    text-align: center;
    min-width: 250px;
}
.serve-col p {
    max-width: 1500px;
    margin: auto;
    font-size: 22px;
    letter-spacing: 0.5px;
    line-height: 1.8;
    padding: 0 60px;
}

/* Mobile queries */
@media (min-width: 1200px) {
    .home-bg-image {
        position: absolute;
        z-index: 0;
        bottom: -100px;
        left: 0;
        padding: 0;
        margin: 0;
        object-fit: cover;
        object-position: 50% 50%;
        width: 100%;
        height: auto;
        min-height: 100%;
    }
    .main-introduction {
        padding-bottom: 100px;
    }
    .main-introduction p {
        padding: 0 50px;
    }
}
@media (max-width: 1199px) {
    .main-introduction {
        padding-bottom: 75px;
        font-size: 25px;
        letter-spacing: 0;
    }
}
@media (max-width: 2000px) {
    .serve-col {
        margin-right: 200px;
    }
}
@media (max-width: 1600px) {
    .serve-col {
        margin-right: 350px;
    }
}
@media (max-width: 991px) {
    .serve-col {
        margin-right: 0;
    }
    .home-serve {
        background-image: url('./logo-transparent-tree-25.png');
    }
}
@media (max-width: 767.98px) {
    .main-introduction {
        padding: 50px 0;
    }
    .main-introduction p {
        margin: 0 30px;
        border-radius: 10px;
        padding: 15px;
        background-color: rgba(255, 255, 255, 0.75);
        font-size: 25px;
    }
    .home-intro-bts {
        margin-top: 50px;
    }
    .mission-row {
        padding: 25px;
    }
    .serve-col {
        padding: 20px;
    }
    .serve-col p {
        border-radius: 10px;
        font-size: 20px;
        letter-spacing: 0.5px;
        line-height: 1.5;
        padding: 10px;
        background-color: rgba(255, 255, 255, 0.75);
    }
}

