.services {
    font-family: Calibri;
}

.services-banner {
    min-height: 120px;
    max-height: 160px;
    opacity: 0.5;
    align-items: center;
    justify-content: center;
    background-color: #F8F6F2;
    text-align: center;
}
.services-banner-2 {
    min-height: 150px;
    max-height: 190px;
    padding: 20px;
    text-align: center;
}
.services-banner-2 h3 {
    letter-spacing: 0.5px;
    opacity: 0.8;
}
.services-banner-2-row-1{
    display: flex;
    align-items: center;
    justify-content: center;
}
.services-banner-2-row-1 img {
    margin-left: 30px;
}

.services-banner-2-row-2 {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 100px;
}

/* Introduction */
.services-hero {
    position: relative;
    padding: 0;
    overflow: hidden;
}
.services-bg-image {
    position: absolute;
    z-index: 0;
    bottom: -225px;
    left: 0;
    padding: 0;
    margin: 0;
    object-fit: cover;
    object-position: 50% 50%;
    width: 100%;
    height: auto;
    min-height: 100%;
}
.services-introduction {
    z-index: 1;
    min-height: 550px;
    max-height: 550px;
}


/* Service details */
.services-details {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    height: 315px;
    background-color: #F8F6F2;
    color: #7A8F3A;
}
.details-row ul {
    display: flex;
    justify-content: center;
    margin: 15px 0;
}
.details-row li {
    list-style-type: none;
    margin-right: 60px;
    opacity: 0.75;
    font-weight: bold;
    font-size: 22px;
    letter-spacing: 0.5px;
    text-align: center;
}

/* Detailed process */
.services-process {
    max-height: 1200px;
    padding: 50px;
    background-image: url('./logo-transparent-tree.png');
    background-size: 1500px 1500px;
    background-position: bottom -355px center;
    background-repeat: no-repeat;
}
/* ROW 1 */
.process-col-1 {
    text-align: center;
}
.process-col-1 p {
    font-size: 22px;
    letter-spacing: 0.5px;
    line-height: 1.8;
}
/* ROW 2 */
.process-row-2 {
    margin: 40px;
    margin-bottom: 0;
}
.process-col-2 {
    width: 75%;
}
.col-2-inner {
    margin-bottom: 45px;
}
.col-2-inner h4 {
    color: #7A8F3A;
}
.col-2-inner-2 span {
    font-size: 20px;
}
/* ROW 3 */
.process-row-3 {
    padding-left: 175px;
    margin-bottom: 25px;
}
.process-row-3 p {
    font-size: 20px;
    letter-spacing: 0.2px;
    line-height: 1.5;
}
.process-row-3 span {
    font-size: 16px;
}
.process-bt {
    justify-content: center;
}

/* Mobile queries */
@media (max-width: 1199.98px) {
    .services-bg-image {
        bottom: 0;
    }
}
@media (max-width: 991.98px) {

}
@media (max-width: 767.98px) {
    .services-banner h1 {
        font-size: 30px;
    }
    .services-banner-2 {
        padding: 10px;
    }
    .services-banner-2 h3 {
        font-size: 25px;
    }
    .services-banner-2-row-1 img {
        margin: 0;
    }
    .services-banner-2-row-2 {
        padding-right: 0;
    }
    .services-details .container {
        margin: 0;
    }
    .details-row li {
        text-align: center;
        font-size: 18px;
        margin-right: 35px;
    }
    .services-process {
        padding: 30px;
    }
    .process-row-2 {
        margin: 5px 0;
    }
    .process-col-2 {
        width: 100%;
    }
    .col-2-inner {
        margin-bottom: 0;
    }
    .col-2-inner h4 {
        font-size: 20px;
    }
    .process-row-3 {
        padding-left: 55px;
        margin-bottom: 10px;
    }
    .process-row-3 .col-6 {
        min-width: 100%;
    }
}
@media (max-width: 575.98px) {
    .services-banner {
        min-height: 80px;
        font-size: 20px;
    }
    .services-banner h1 {
        font-size: 20px;
    }
    .services-banner-2 h3 {
        font-size: 18px;
    }
    .details-row {
        height: 100%;
    }
    .details-row .col-12 ul{
        padding-left: 0;
        margin: 18px 0;
    }
    .details-row li {
        text-align: center;
        font-size: 12px;
        margin-right: 10px;
    }
    .process-col-1 h2 {
        font-size: 26px;
    }
    .process-col-1 p {
        font-size: 16px;
    }
    .process-col-2.offset-1 {
        margin-left: 0;
    }
    .col-2-inner h4{
        font-size: 16px;
    }
    .col-2-inner-2 span {
        font-size: 14px;
    }
    .process-row-3 {
        padding-left: 10px;
    }
    .process-row-3 h4 {
        font-size: 16px;
    }
    .process-row-3 p {
        padding-left: 8px;
        font-size: 14px;
    }
    .process-row-3 span {
        padding-left: 8px;
        font-size: 12px;
    }
    .process-bt {

    }
}
@media (max-width: 400px) {
    .services-banner {
        min-height: 80px;
        font-size: 18px;
        padding-left: 5px;
        padding-right: 5px;
    }
    .services-banner h1 {
        font-size: 20px;
    }
}
