.footer {
    width: 100%;
    min-height: 175px;
    max-height: 300px;
    display: flex;
    align-items: center;
    background-color: rgba(101, 157, 189, 0.75);
}

.footer .container {
    width: 100%;
    min-height: 175px;
    max-height: 300px;
    display: flex;
    justify-content: center;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.contact-list {
    text-align: left;
    padding-left: 0;
}

.footer-row {
    margin: 10px 0 10px 0;
    width: 100%;
    min-height: 150px;
    max-height: 300px;
    align-items: center;
    justify-content: center;
    border-style: solid;
    border-width: 5px;
    border-color: rgba(5, 56, 107, 0.75);
}

.social-col {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 503px) {
    .bi-facebook {
        width: 40px;
        height: 40px;
    }
    .bi-instagram {
        width: 40px;
        height: 40px;
    }
}
