.family {

}

.family-main {
    display: flex-grow;
    height: 900px;
    min-height: 200px;
    text-align: center;
    align-items: center;
    justify-content: center;
}
